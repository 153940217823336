/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "assets/styles/variables";
@import 'ngx-toastr/toastr-bs5-alert';

.button-primary {
    background-color: $primary-color;
    color: white;
}

.button-secondary {
    background-color: $secondary-color;
    color: white;
}

body {
    background-color: $background-color !important;
}
